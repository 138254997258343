//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MapView from "./MapView.vue";
//import DateFilter from './Filters/DateFilter.vue';
import FunnelFilter from "./Filters/FunnelFilter.vue";
import PlanBoardModel from "./PlanBoardModel";
import { mapActions, mapGetters, mapState } from "vuex";
import { DayPilot } from "daypilot-pro-vue";
import db from "../../db";
import store from "../../store";

export default {
  name: "PlanBoardMap",
  components: { MapView, /*DateFilter,*/ FunnelFilter },
  data() {
    return {
      sortDirect: true,
      dropDownValuesWorkOrders: {},
      dropDownValuesAppointments: {},
      filtersDropDownValuesWorkOrders: {},
      filtersDropDownValuesAppointments: {},
      module: "workOrders",
    };
  },
  computed: {
    tasks: function () {
      return PlanBoardModel.mapTasks(this.appointmentsMapTasks, {
        filters: this.filters,
      });
    },

    filters: function () {
      return PlanBoardModel.filters(this.filtersRaw, {
        filtersDropDownValuesWorkOrders: this.filtersDropDownValuesWorkOrders,
        filtersDropDownValuesAppointments: this
          .filtersDropDownValuesAppointments,
      });
    },

    todayDate: function () {
      return DayPilot.Date.today().toString("dddd d, MMMM yyyy");
    },

    weekNumber: function () {
      return DayPilot.Date.today().weekNumber();
    },
    ...mapGetters("subscribe", ["appointmentsMapTasks"]),
    ...mapState(["techniciansRaw", "loading"]),
    ...mapState(["currentDate"]),
    ...mapState("subscribe", ["filtersRaw"]),

    ...mapGetters(["locale", "language"]),
    ...mapGetters("user", { profile: "profile", loggedIn: "loggedIn" }),
  },
  watch: {},
  methods: {
    ...mapActions("subscribe", [
      "updatePlanBoardFiltersMap",
      "initPlanBoardFiltersMap",
      "unbindAppointmentsMap",
    ]),

    async getdropDownValuesWorkOrders() {
      const tempDropDownValues = {};
      const firePath = "tenants/" + store.state.tenantID + "/modules/";
      const result = await db.collection(firePath).doc("workOrders").get();
      const values = result.data() ? result.data().dropDownValues : {};

      for (const val in values) {
        tempDropDownValues[val] = values[val].map((v) => {
          return { id: v, name: v };
        });
      }
      this.dropDownValuesWorkOrders = tempDropDownValues;
      this.filtersDropDownValuesWorkOrders = this.dropDownValuesWorkOrders;
    },
    async getdropDownValuesAppointments() {
      const tempDropDownValues = {};
      const firePath = "tenants/" + store.state.tenantID + "/modules/";
      const result = await db.collection(firePath).doc("appointments").get();
      const values = result.data() ? result.data().dropDownValues : {};

      for (const val in values) {
        tempDropDownValues[val] = values[val].map((v) => {
          return { id: v, name: v };
        });
      }
      this.dropDownValuesAppointments = tempDropDownValues;
      this.filtersDropDownValuesAppointments = this.dropDownValuesAppointments;
    },
    submitMapDateFilter: function (period) {
      this.updatePlanBoardFiltersMap({ tasks: { period } });
    },
    submitMapFilter: function (filters) {
      this.updatePlanBoardFiltersMap({ mapTasks: { filters } });
    },
    resetMapFilter: function () {
      this.updatePlanBoardFiltersMap({
        mapTasks: PlanBoardModel.getEmptyFilter({
          filtersDropDownValuesWorkOrders: this
            .filtersDropDownValuesWorkOrders,
          filtersDropDownValuesAppointments: this
            .filtersDropDownValuesAppointments,
        }),
      });
    },
  },
  beforeMount() {
    this.initPlanBoardFiltersMap();
    this.getdropDownValuesWorkOrders();
    this.getdropDownValuesAppointments();
  },
  beforeDestroy() {
    this.unbindAppointmentsMap();
  },
};
