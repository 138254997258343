//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//import {tt} from '@tomtom-international/web-sdk-maps';
//import '@tomtom-international/web-sdk-maps';
//import '@tomtom-international/web-sdk-services';
//var tomtom = tt;
//// eslint-disable-next-line
//console.log(tt);
import { date } from "quasar";

export default {
  name: "map-view",
  props: { tasks: {}, id: {} },
  data() {
    return {
      searchInput: "",
      searchLocation: "",
      latCC: "52.307971",
      lonCC: "6.521421",
      map: {},
      workOrderIcon: "",
      locationOrders: [],
      index: 0,
      markers: {},
    };
  },
  methods: {
    citySearch() {
      const app = this;
      const cityOrder = app.mapOrders.find(
        (e) => e.wo.city === app.searchInput
      );
      if (cityOrder) {
        app.latCC = cityOrder.wo.latitude;
        app.lonCC = cityOrder.wo.longitude;
        this.map.panTo([app.latCC, app.lonCC]);
      }
    },
    orderNextBtn() {
      this.index++;
      if (this.index > this.locationOrders.length - 1)
        this.index = this.locationOrders.length - 1;
      this.goToLocationFind();
    },
    orderPrevBtn() {
      this.index--;
      if (this.index < 0) this.index = 0;
      this.goToLocationFind();
    },
    locationSearch() {
      this.index = 0;
      this.locationOrders = this.mapOrders.filter(
        (e) => e.wo.street.indexOf(this.searchLocation) + 1
      );
      this.dataSort();
    },
    dataSort() {
      const today = Date.parse(new Date());
      this.locationOrders.sort(function (a, b) {
        const first = Date.parse(a.wo.dateTimeOfNotification);
        const second = Date.parse(b.wo.dateTimeOfNotification);
        return Math.abs(today - first) > Math.abs(today - second);
      });
    },
    goToLocationFind() {
      if (this.locationOrders.length > 0) {
        this.latCC = this.locationOrders[this.index].wo.latitude;
        this.lonCC = this.locationOrders[this.index].wo.longitude;
        this.map.panTo([this.latCC, this.lonCC]);
      }
    },
    addMarkers(value) {
      const app = this;
      const plannedStartDateTime =
        value.wo &&
        value.plannedStartDateTime &&
        typeof value.plannedStartDateTime.toDate === "function"
          ? date.formatDate(
              value.plannedStartDateTime.toDate(),
              "YYYY-MM-DD HH:mm"
            )
          : "";
      const plannedEndDateTime =
        value.wo &&
        value.plannedEndDateTime &&
        typeof value.plannedEndDateTime.toDate === "function"
          ? date.formatDate(value.plannedEndDateTime.toDate(), "YYYY-MM-DD HH:mm")
          : "";

      const str1 =
        value.wo && value.wo.number && typeof value.wo.number !== "undefined"
          ? value.wo.number
          : "";
      const str2 =
        value.wo && value.wo.name && typeof value.wo.name !== "undefined"
          ? value.wo.name
          : "";
      const str3 =
        value.wo &&
        value.wo.accountResponsible &&
        value.wo.accountResponsible.name &&
        typeof value.wo.accountResponsible.name !== "undefined"
          ? value.wo.accountResponsible.name
          : "";
      const str4 =
        value.wo &&
        value.wo.locationName &&
        value.wo.locationName.name &&
        typeof value.wo.locationName.name !== "undefined"
          ? value.wo.locationName.name
          : "";
      const str5 =
        value.wo &&
        value.status &&
        value.status &&
        typeof value.status !== "undefined"
          ? value.status
          : "";
      const str6 = plannedStartDateTime;
      const str7 = plannedEndDateTime;

      const labelStr2 = app.$t("workOrders.fields.name");
      const labelStr3 = app.$t("workOrders.fields.responsibleServiceEngineer");
      const labelStr4 = app.$t("workOrders.fields.location");
      const labelStr5 = app.$t("appointments.fields.status");
      const labelStr6 = app.$t("appointments.fields.plannedStartDateTime");
      const labelStr7 = app.$t("appointments.fields.plannedEndDateTime");

      // eslint-disable-next-line
      app.markers[value.id] = new tomtom.L.marker(
        [value.wo.latitude, value.wo.longitude],
        { icon: app.workOrderIcon }
      )
        .addTo(app.map)
        .bindPopup(
          "<h5>" +
            str1 +
            "</h5>" +
            "<p>" +
            labelStr2 +
            ": " +
            str2 +
            "</p>" +
            "<p>" +
            labelStr3 +
            ": " +
            str3 +
            "</p>" +
            "<p>" +
            labelStr4 +
            ": " +
            str4 +
            "</p>" +
            "<p>" +
            labelStr5 +
            ": " +
            str5 +
            "</p>" +
            "<p>" +
            labelStr6 +
            ": " +
            str6 +
            "</p>" +
            "<p>" +
            labelStr7 +
            ": " +
            str7 +
            "</p>"
        );
    },
  },
  computed: {
    mapOrders() {
      const ordersGeoSet = this.tasks.filter(
        (e) =>
          e.wo.latitude &&
          e.wo.longitude &&
          typeof e.wo.latitude !== "undefined" &&
          typeof e.wo.longitude !== "undefined"
      );
      //let findedOrders = this.searchLocation ? ordersGeoSet.filter(e => e.wo.street.indexOf(this.searchLocation) +1) : ordersGeoSet;
      //return findedOrders;
      return ordersGeoSet;
    },
  },
  watch: {
    mapOrders() {
      const app = this;
      const ordersId = this.mapOrders.map((o) => (o = o.id));
      this.mapOrders.forEach((value) => this.addMarkers(value));
      for (const m in app.markers) {
        if (!ordersId.includes(m)) app.markers[m].remove();
      }
    },
  },
  mounted: function () {
    const app = this;
    //this.mapOrders = this.tasks.filter(e => e.wo.latitude && e.wo.longitude && typeof e.wo.latitude !== 'undefined' && typeof e.wo.longitude !== 'undefined');
    //this.mapOrders = this.tasks.filter(e => e.wo.latitude && e.wo.longitude && typeof e.wo.latitude !== 'undefined' && typeof e.wo.longitude !== 'undefined');
    //this.latCC = this.mapOrders.length > 0 ? this.mapOrders[0].latitude: this.latCC;
    //this.lonCC = this.mapOrders.length > 0 ? this.mapOrders[0].longitude: this.lonCC;

    // eslint-disable-next-line
    this.map = tomtom.L.map("map", {
      key: "L935A8Y7Nkl5XFx34fsVc1mYLKYM8O2w",
      basePath: "sdk",
      center: [this.latCC, this.lonCC],
      zoom: 14,
      layer: "basic",
      language: "nl-NL",
      styleUrlMapping: {
        main: {
          basic: "/map/sdk/styles/basic_main.json",
          hybrid: "/map/sdk/styles/hybrid_main.json",
          labels: "/map/sdk/styles/labels_main.json",
        },
        night: {
          basic: "/map/sdk/styles/basic_night.json",
          hybrid: "/map/sdk/styles/hybrid_night.json",
          labels: "/map/sdk/styles/labels_night.json",
        },
      },
    });
    // eslint-disable-next-line
    this.workOrderIcon = tomtom.L.icon({
      iconUrl: "https://img.icons8.com/flat_round/64/000000/error--v1.png",
      iconSize: [30, 30],
      iconAnchor: [15, 30],
      popupAnchor: [0, -30],
    });
    this.mapOrders.forEach((value) => this.addMarkers(value));
    // eslint-disable-next-line
    tomtom
      .controlPanel({
        position: "topright",
        collapsed: false,
        close: null,
        closeOnMapClick: false,
      })
      .addTo(app.map)
      .addContent(document.getElementById("labels-container"));
  },
};
