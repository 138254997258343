import * as numeral from 'numeral';
import {date} from "quasar";
import store from "../../store";
import firebase from '@/firebase';

export default class ListValues {

  static transform(values, dynFieldTypes) {

    // dynamic fields

    return values.map((v) => {

      // only one time transform
      if (v.transformed) {
        return v;
      }
      v.transformed = true;

      for (const field in v) {
        const type = dynFieldTypes[field] ? dynFieldTypes[field].type : '';


        // [Checkbox web UI](https://www.notion.so/Checkbox-web-UI-e51973ed7d354879aaf9eacd0c5a55ef)
        if (type === 'checkbox') {
          if (v[field] === null) {
            v[field] = '';
          } else {
            v[field] = v[field] === true ? '✓' : '';
          }
        }

        // [Currency field web UI](https://www.notion.so/Currency-field-web-UI-4576b5f641ca46b18a130c4261d1c185)
        if (type === 'currency') {
          if (v[field] === null) {
            v[field] = '-';
          } else if (v[field] === 0 || v[field] && Number.isInteger(v[field])) {
            v[field] = numeral(v[field] / 10000).format('$-0.00');
          }
        }

        // [Date web UI](https://www.notion.so/Date-web-UI-e93dd11ddca6452daac6c7ec824baedd)
        if (type === 'date') {
          if (v[field] === null) {
            v[field] = '-';
          } else if (v[field] && typeof v[field].toDate === "function") {
            v[field] = date.formatDate(v[field].toDate(), store.state.dateMask);
          }
        }

        // [Date time web UI](https://www.notion.so/Date-time-web-UI-12af75cac0694ffc9812814091471a19)
        if (type === 'datetime') {
          if (v[field] === null) {
            v[field] = '-';
          } else if (v[field] && typeof v[field].toDate === "function") {
            v[field] = date.formatDate(v[field].toDate(), store.state.dateTimeMask);
          }
        }

        // [Decimal web UI](https://www.notion.so/Decimal-web-UI-5e05d48373964ef5aa6566a3e1fafe53)
        if (type === 'decimal') {
          if (v[field] === null) {
            v[field] = '-';
          } else if (v[field] && Number.isInteger(v[field])) {
            v[field] = numeral(v[field]).format('0.00');
          }
        }

        // [Dropdown web UI](https://www.notion.so/Dropdown-web-UI-194ca6cdeae34c1d95fc309bf457014a)
        if (type === 'dropdown') {
          if (v[field] === null) {
            v[field] = '-';
          } else if (v[field] && v[field].hasOwnProperty("name")) {
            v[field] = v[field].name;
          }
        }

        // [Email web UI](https://www.notion.so/Email-web-UI-e8ea25bc9e9847208dd6c838d8a3a8e0)
        if (type === 'email') {
          if (v[field] === null) {
            v[field] = '-';
          }
        }

        // [Number web UI](https://www.notion.so/Number-web-UI-7e6148b62a154b4b967abbd5b26a78dc)
        if (type === 'number') {
          if (v[field] === null) {
            v[field] = '-';
          }
        }

        // [Phone web UI](https://www.notion.so/Phone-web-UI-768e8eb761d04a04965a04ff67ed069a)
        if (type === 'phone') {
          if (v[field] === null) {
            v[field] = '-';
          }
        }

        // Percentage
        if (type === 'percentage') {
          if (v[field] === null) {
            v[field] = '-';
          } else if (v[field] === 0 || v[field] && Number.isInteger(v[field])) {
            v[field] = Number((v[field] / 10000).toFixed(2));
            v[field] = numeral(v[field]).format('0.00') + '%';
          }
        }

        // [Related field web UI](https://www.notion.so/Related-field-web-UI-e8236a12d49648feb1a71f42bb398efb)
        if (type === 'relatedField') {
          if (v[field] === null || (v[field] && v[field].ID === '')) {
            v[field] = '-';
          } else if (v[field] && v[field].hasOwnProperty("name")) {
            v[field] = v[field] ? v[field].name : '';
          }
        }

        // [Text web UI](https://www.notion.so/Text-web-UI-7d160745f2294fcdb68eaad399dab82f)
        if (type === 'text') {
          if (v[field] === null || v[field] === '') {
            v[field] = '-';
          }
        }

      }

      return v;
    });
  }

  static locFields() {
    const lf = {
      createdAt: {label: 'Created At', type: 'datetime', order: 1},
      createdBy: {label: 'Created By', type: 'relatedField', order: 1},
      changedAt: {label: 'Changed At', type: 'datetime', order: 1},
      changedBy: {label: 'Changed By', type: 'relatedField', order: 1},
    }
    return lf;
  }

  static getValidColumns(fields) {
    const secFields = ['attachments', 'media', 'subForm', 'subrecord', 'relatedMultiSelect'];
    const hiddenFields: Array<string> = []
    for (const f in fields){
      if (fields[f].hidden) {
        hiddenFields.push(fields[f].name);
      }
    }
    return Object.fromEntries(
        Object.entries(fields)
        .filter((e: any) => secFields.indexOf(e[1].type) === -1 && !hiddenFields.includes(e[0]))
        .sort((a: any, b: any) => { return (a[1].label ?? a[0]).toLowerCase().localeCompare((b[1].label ?? b[0]).toLowerCase()) })
    );
  }

  static convertToObject(data, dateType = 'timestamp', notNeedJsonStringifyFields = []) {
    const saveTs = {};
    const saveFields: any = {};

    notNeedJsonStringifyFields.map((f) => {
      if (data[f]) {
        saveFields[f] = data[f];
      }
    });

    for (const f in data) {
      if (data[f] && typeof data[f].toDate === "function") {
        saveTs[f] = data[f];
      }
    }

    data = JSON.parse(JSON.stringify(data));

    for (const f in saveTs) {
      data[f] = saveTs[f];
    }

    for (const f in saveFields) {
      data[f] = saveFields[f];
    }

    for (const f in data) {
      if (data[f] && data[f].hasOwnProperty('seconds') && data[f].hasOwnProperty('nanoseconds')) {
        data[f] = this.fromObjToTimestamp(data[f], dateType);
      } else {
        if (Array.isArray(data[f])) {
          data[f].map(d => {
            for(const v in d) {
              if (d[v] && d[v].hasOwnProperty('seconds') && d[v].hasOwnProperty('nanoseconds')) {
                d[v] = this.fromObjToTimestamp(d[v], dateType);
              }
            }
          });
        }
      }
    }

    return data;
  }

  static fromObjToTimestamp(o, dateType = 'timestamp') {
    const sec = o.seconds
    const nano = o.nanoseconds;
    const timestamp = (firebase as any).firestore.Timestamp.fromMillis(sec * 1000 + nano / 1000);
    if (dateType === 'timestamp') {
      return timestamp;
    }
    if (dateType === 'ISO-8601') {
      return timestamp.toDate().toISOString();
    }
  }

  static transformDiv10k(values, formData) {

    const dynFieldTypes = formData.dynamicFields;
    const dynSections = formData.dynamicSections;
    const dynModules = store.state.dynamicModules;

    for (const field in values) {
      if (Array.isArray(values[field])) {
        let dynFieldsSub = {};
        if (dynFieldTypes[field]) {
          dynFieldsSub = dynFieldTypes[field]['fields'];
        } else {
          if (dynSections[field] && dynSections[field].type === 'relatedRecords') {
            dynFieldsSub = dynModules[dynSections[field].relatedModule]
              ? dynModules[dynSections[field].relatedModule].fields
              : {};
          }
        }

        for (const ff in values[field]) {
          values[field].map(d => {
            if (!d._t10ka) {
              d._t10ka = true;
              for (const v in d) {
                const tt = dynFieldsSub[v] ? dynFieldsSub[v].type : '';
                if (tt === 'currency' || tt === 'percentage') {
                  //d[v] =Number(d[v] / 10000).toFixed(2); // string format
                  //console.log(d[v]);
                  d[v] = Number(Number(d[v] / 10000).toFixed(2));
                  //console.log(d[v]);
                }
              }
            }
          });
        }
      } else {
        // not array
        if (!values._t10k) {
          const type = dynFieldTypes[field] ? dynFieldTypes[field].type : '';
          if (type === 'currency' || type === 'percentage') {
            //values[field] = Number(values[field] / 10000).toFixed(2); // string format
            //console.log(values[field]);
            values[field] = Number(Number(values[field] / 10000).toFixed(2));
            //console.log(values[field]);
          }
        }
      }
    }
    values._t10k = true;
    return values;
  }

  static getEmailAddresses(emailList, docData) {
    const itsArray = Array.isArray(emailList);
    const emailListArr = !itsArray ? (emailList || '').split(',') : emailList;
    const res = (emailListArr).map(em => {
      if (docData[em.trim()]) {
        return docData[em.trim()];
      } else {
        return em;
      }
    }).filter(em => em);
    //console.log(res);
    return !itsArray ? res.join(', ') : res;
  }

}
